@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Solway:wght@300;400;500;700;800&family=Sorts+Mill+Goudy:ital@0;1&family=Tiro+Gurmukhi&display=swap');

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    color: white;
    font-family: 'Tiro Gurmukhi', serif;
    font-size: 18px;
    min-height: 100vh;
    max-width: 100vw;
    background-image: url('images/background.png');
    padding-top: 20px;
    background-attachment: fixed;
    background-size: cover;
}

/* HEADER */

.nav {
    min-height: 130px;
}

#container {
    width: 80%;
    font-family: 'Solway', serif;
    font-size: 16px;
    text-align: center;
}

.img-logo {
    width: 10rem;
}

.nav-item a,
.nav-link,
.dropdown-item {
    margin-right: 20px;
    text-align: center;
    transition: all 0.6s ease-in-out;
}

#last {
    margin-right: 0;
}

.nav-item a:hover,
.nav-link:hover,
nav-link.active {
    background-color: #81717150 !important;
    border-radius: 5px;
    transition: all 0.6s ease-in-out;
    transform: scale(1.1);
}

.dropdown-item:hover,
.dropdown-item.active {
    color: black !important;
    background-color: #8171712a !important;
}

/* HOME */
section {
    margin: 30px 50px;
    padding: 0 8%;
    position: relative;
}

section::before {
    content: "";
    background-color: white;
    border-radius: 10px;
    opacity: 0.2;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.homepage,
.kontakt {
    height: 70vh;
}

.hero-section,
.kontakt-section {
    padding: 0 8%;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hero-section-description {
    margin-right: 50px;
}

.hero-section-image-wrapper {
    text-align: end;
    width: 60%;
}

.hero-section-title {
    position: relative;
    font-family: 'Sorts Mill Goudy', serif;
    font-size: 5rem;
    font-weight: 700;
    color: #d8e3ec;
    text-shadow: -4px 4px 1px rgba(50, 50, 0, 0.8);
    animation: 4S ease-out 0s 1 slideInFromLeft;

}

.hero-section-subtitle {
    font-family: 'Sorts Mill Goudy', serif;
    position: relative;
    color: black;
    letter-spacing: 5px;
    font-size: 2.5em;
    margin-top: 1em;
    text-shadow: -2px 2px 1px rgba(150, 150, 148, 0.8);
    text-align: end;
}

.hero-section-image {
    position: relative;
    width: 80%;
}

/* __________________________- */

.section-title {
    font-family: 'Sorts Mill Goudy', serif;
    background-image: url('images/title9.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    hyphens: auto;
    height: 200px;
    border-radius: 5px;
    color: #E8EBF1;
    padding: 30px;
    font-size: 3.5rem;
    text-shadow: -4px 4px 1px rgba(32, 32, 32, 0.8);
}

.purple {
    color: #541212;
    transition: all 0.6s ease-in-out;
}


.purple:hover {
    color: #54121271;
    transition: all 0.6s ease-in-out;
}

/* AKTUELLES */
.ausbildung,
.berufstatigkeit,
.mitgliedschaften,
.kontakt,
.aktuelles,
.auf-sexualkultur,
.auf-sexualpadagogik,
.auf-sexuellegesundheit,
.auf-sozialpadagogik,
.p-sexualkultur {
    padding: 50px 20%;
}

.title-aktuelles {
    background-image: url('images/title-aktuelles.png');
    background-position: bottom;
}

.aktuelles-description {
    font-size: 18px;
    padding-top: 10px;
    padding-right: 30px;
}

.aktuelles-image-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    /* display: block; */
}

.hyphens {
    word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.source-aktuelles {
    font-size: 12px;
}

.with-image {
    padding: 10px 0;
}

.aktuelles-image {
    width: 200px;
}

.new-display {
    text-align: center;
    display: flex;
    align-items: center;
}

.card {
    /* background-color: rgb(252, 252, 252); */
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    color: black;
    padding: 30px;
    box-shadow: -12px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -12px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -12px 10px 5px 0px rgba(0, 0, 0, 0.75);
    margin: 2rem 0;
}

.border-card {
    border-radius: 10px;
    border: 3px solid #541212 !important;
    margin-bottom: 30px;
    padding: 30px;
}

.card-subtitle {
    align-self: flex-start;
    font-weight: bold;
    padding-bottom: 20px;
    font-family: 'Sorts Mill Goudy', serif;
}

.card-year {
    font-weight: bold;
}

.group-list {
    margin-top: 10px;
}

.source {
    font-size: 14px;
    font-style: italic;
    color: #541212;
    word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.source:hover,
.link-text:hover {
    color: blueviolet;
}

.link-text {
    color: #541212;
}


/* KONTAKT */
.kontakt-section-title {
    font-family: 'Sorts Mill Goudy', serif;
    font-size: 4rem;
    font-weight: 700;
    color: #d8e3ec;
    text-shadow: -4px 4px 1px rgba(50, 50, 0, 0.8);
    animation: 4S ease-out 0s 1 slideInFromLeft;
}

.kontakt-section-description {
    width: 100%;
}

.kontakt-section-subtitle {
    text-align: start;
    font-size: 1.2em;
    position: relative;
    color: black;
    letter-spacing: 5px;
    margin-top: 1em;
    margin-bottom: 1EM;
    text-shadow: -2px 2px 1px rgba(150, 150, 148, 0.8);
}

.kontakt-section-details {
    font-size: 1.4rem;
    margin-bottom: 4px;
    font-weight: bold;
    color: black;
}

.email-link {
    color: #ffffff;
    text-shadow: none;
    transition: all 0.6s ease-in-out;
}

.email-link:hover {
    color: #212529;
    transition: all 0.6s ease-in-out;
}

/* FOOTER */
.footer {
    color: rgba(240, 248, 255, 0.521);
    background-color: #212529;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer h5 {
    font-family: 'Sorts Mill Goudy', serif;
    font-size: 16px;
}

.footer p {
    font-size: 10px;
    margin-bottom: 0;
}

.footer-link {
    color: rgb(137, 101, 172);
    font-style: italic;
    text-decoration: none;
    transition: all 0.6s ease-in-out;
}

.footer-link:hover {
    color: white;
    transition: all 0.6s ease-in-out;
}

.title-arbeits {
    background-image: url('images/title7.png');
    background-position: center -75px;
}

.title-publikationen {
    background-image: url('images/title8.png');
    background-position: 0;
}

/* BREADCRUMB */
.breadcrumb {
    background-color: #21252936 !important;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 16px;
}


.breadcrumb-item a {
    color: white;
    padding: 5px;
    transition: all 0.6s ease-in-out;
}

.breadcrumb-item a:hover {
    color: rgba(0, 0, 0, 0.781);
    border-radius: 2px;
    background-color: white;
    transition: all 0.6s ease-in-out;
}

.breadcrumb-item.active {
    color: black !important;
}

.breadcrumb-section {
    color: white;
}


@media(max-width: 1400px) {

    #container {
        width: 85%;
        font-size: 14px;
    }

    .nav-item a,
    .nav-link,
    .dropdown-item {
        margin-right: 18px;
    }

    .img-logo {
        width: 9rem;
    }

    /* HOME */
    section {
        padding: 0 2%;
    }

    .hero-section,
    .kontakt-section {
        padding: 0 5%;
    }

    .hero-section-description {
        margin-right: 20px;
    }

    .hero-section-title {
        font-size: 4rem;
    }

    .hero-section-subtitle {
        font-size: 2em;
    }

    .hero-section-image {
        width: 100%;
    }

    .ausbildung,
    .berufstatigkeit,
    .mitgliedschaften,
    .kontakt,
    .aktuelles,
    .auf-sexualkultur,
    .auf-sexualpadagogik,
    .auf-sexuellegesundheit,
    .auf-sozialpadagogik,
    .p-sexualkultur {
        padding: 50px 10%;
    }

}

@media(max-width: 1200px) {

    #container {
        font-size: 13px;
        width: 95%;
    }

    .kontakt-image {
        width: 80%;
    }

    .img-logo {
        width: 8rem;
    }

    .nav-item a,
    .nav-link,
    .dropdown-item {
        margin-right: 2px;
    }

    .title-arbeits,
    .title-publikationen {
        font-size: 3rem;
        height: 180px;
    }
}

@media(max-width: 992px) {
    #container {
        font-size: 16px;
        width: 90%;
    }

    .img-logo {
        width: 10rem;
    }

    /* HOME */
    section {
        padding: 0 2%;
    }

    .hero-section {
        padding: 5% 4%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .kontakt-section {
        padding: 5% 4%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .kontakt-section-description {
        height: 0%;
    }

    .hero-section-description {
        margin-right: 0;
    }

    .kontakt-section-details {
        text-align: center;
        font-size: 18px;
    }

    .hero-section-title {
        margin-top: 4rem;
        font-size: 4rem;
        text-align: center;
    }

    .kontakt-section-title {
        margin-top: 0;
        font-size: 3rem;
        text-align: center;
    }

    .hero-section-subtitle,
    .kontakt-section-subtitle {
        font-size: 1em;
        text-align: center;
    }

    .hero-section-image {
        margin: 1rem 0;
        width: 100%;
        margin-bottom: 3rem;
    }

    .kontakt-image {
        visibility: hidden;
    }

    .title-aktuelles,
    .section-title {
        height: 150px;
    }

    .title-arbeits,
    .title-publikationen {
        font-size: 2.5rem;
        height: 180px;
    }

    .breadcrumb {
        font-size: 14px !important;
    }

}

@media(max-width: 600px) {

    .kontakt-section-title {
        margin-top: 0;
        font-size: 1.5rem;
        text-align: center;
    }

    #container {
        font-size: 14px;
        width: 90%;
    }

    .img-logo {
        width: 9rem;
    }

    /* HOME */
    section {
        padding: 0;
        margin: 30px;
    }

    .hero-section,
    .kontakt-section {
        padding: 5% 5%;
    }

    .hero-section-description {
        height: 20%;
        margin-top: 40px;
    }

    .hero-section-title {
        margin-top: 2rem;
    }

    .kontakt-section-title {
        margin-top: 0;
        font-size: 2.5rem;
    }

    .hero-section-subtitle {
        margin-top: 0;
        font-size: 1.5em;
    }

    .kontakt-section-subtitle {
        font-size: 1.2em;
        margin-top: 1rem;
    }

    .hero-section-image-wrapper {
        width: 80%;
        margin-top: 3rem;
    }



    .hero-section-image {
        width: 100%;
    }

    .kontakt-section-details {
        font-size: 20px;
        text-align: start;
    }

    .title-aktuelles,
    .section-title {
        height: 120px;
        text-align: center;
    }

    /* FOOTER */
    .footer h5 {
        margin: 10px 5px 5px 0;
        font-family: 'Sorts Mill Goudy', serif;
        font-size: 13px;
    }

    .footer p {
        font-size: 8px;
        margin-bottom: 10px;
    }

    .ausbildung,
    .berufstatigkeit,
    .mitgliedschaften,
    .kontakt,
    .aktuelles,
    .auf-sexualkultur,
    .auf-sexualpadagogik,
    .auf-sexuellegesundheit,
    .auf-sozialpadagogik,
    .p-sexualkultur {
        padding: 30px 5%;
    }

    .title-arbeits,
    .title-publikationen {
        font-size: 1.8rem;
        height: 140px;
    }

    .breadcrumb {
        visibility: hidden;
    }
}

@media(max-width: 480px) {
    .hero-section-title {
        margin-top: 0rem;
        font-size: 3rem;
    }

    .hero-section-subtitle {
        font-size: 1rem;
        text-align: center;
    }

    .kontakt-section-subtitle {
        visibility: hidden;
    }

    .aktuelles-description {
        font-size: 16px;
        padding-top: 10px;
        padding-right: 30px;
    }

    .title-arbeits,
    .title-publikationen {
        padding: 10px;
        font-size: 1.5rem;
        height: 80px;
    }

    .title-aktuelles,
    .section-title {
        height: 120px;
        font-size: 2.5rem;
    }
}

@media(max-width: 370px) {
    .hero-section-title {
        margin-top: 0;
        font-size: 2.8rem;
    }

    .hero-section-subtitle {
        font-size: 0.8em;
    }

    .kontakt-section-title {
        font-size: 2rem;
    }

    .kontakt-section-details {
        font-size: 18px;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    30% {
        transform: translateX(20px);
    }

    40% {
        transform: translateX(-20px);
    }

    60% {
        transform: translateX(10px);
    }

    80% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0);
    }
}